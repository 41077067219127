import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EcontractDetailsComponent } from './components/annuity-econtracts/econtract-details/econtract-details.component';
import { AppConstants, SECURE_SITES } from './shared/constants';
import { AnnuityEcontractsComponent } from './components/annuity-econtracts/annuity-econtracts.component';

const siteName = (window as { [key: string]: any })[AppConstants.SITE_NAME] as keyof typeof SECURE_SITES;
const siteURL = AppConstants.SITE_TYPE + SECURE_SITES[siteName];
const routes: Routes = [
  { path: siteURL + '/pendingbusiness/annuityecontract', component: AnnuityEcontractsComponent },
  { path: siteURL + `/pendingbusiness/annuityecontract/econtractdetails/:contractnumber/:status`, component: EcontractDetailsComponent },
  { path: '**', redirectTo: siteURL + '/pendingbusiness/annuityecontract' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
