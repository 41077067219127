export const AppConstants = {
  PAYLOAD: {
    GET_ECONTRACT_LIST_URL_SERVICE_ID: 'getList',
    GET_ECONTRACT_DETAILS_URL_SERVICE_ID: 'getDetails',
  },
  STATIC: {
    EXPERIENCING_TECHNICAL_DIFFICULTIES:
      'We are currently experiencing technical difficulties. Please try again later.'
  },
  SELECT_DROPDOWN: [
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: '150', value: '150' }
  ],
  SITE_TYPE: 'secure',
  SITE_NAME: 'siteName',
  HUB_PENDING: '/pendingbusiness'
}

/* Window Nav constants */
export const SECURE_SITES = {
  'SecureProducer': '/producer',
  'SecureInternal': '/internal',
  'SecureConsumer': '/consumer',
  'SecurePlanSponsor': '/employer',
  'SecureRIA': '/ria'
}

export const TableConfig = {
  sorting: true,
  tableFoot: false,
  defaultSortOrder: 'asc',
  clickSortOrder: 'desc',
  noRecordsText: 'NO RECORDS FOUND',
  handleSubDetailRow: false,
  currentPage: 0,
  addNewRowLink: false,
  deleteIndRowLink: false,
  handleDelNRowInApp: false,
  handleAddNRowInApp: false
}
