import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnnuityEcontractsComponent } from './components/annuity-econtracts/annuity-econtracts.component';
import { DataService } from './shared/data-service';
import { EntRestClientService } from '@ng/ent-utilities';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { EntLoaderModule } from '@ng/ent-components/ent-loader';
import { Utils } from './shared/utils';
import { EntTablesModule } from '@ng/ent-components/ent-tables';
import { EntAutocompleteModule } from '@ng/ent-components/ent-autocomplete';
import { EntFormWidgetsModule } from '@ng/ent-components/ent-form-widgets';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EcontractDetailsComponent } from './components/annuity-econtracts/econtract-details/econtract-details.component';
import { EntTabsModule } from '@ng/ent-components/ent-tabs';
import { AnalyticsService } from './shared/analytics-service';

@NgModule({
  declarations: [
    AppComponent,
    AnnuityEcontractsComponent,
    EcontractDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    EntLoaderModule,
    EntTablesModule,
    EntAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    EntFormWidgetsModule,
    EntTabsModule,
    AuthModule.forRoot({
      domain: environment.auth0[environment.auth0EnvName]['domain'],
      clientId: environment.auth0[environment.auth0EnvName]['clientId'],
      audience: environment.auth0[environment.auth0EnvName]['audience'],
      redirectUri: environment.auth0[environment.auth0EnvName]['redirectUri'],
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          {
            uri: '*',
            tokenOptions: {
              audience: environment.auth0[environment.auth0EnvName]['audience']
            },
          }
        ],
      },
    })
  ],
  providers: [DataService, EntRestClientService, Utils, DatePipe, AnalyticsService,
    { provide: APP_BASE_HREF, useValue: '/' },
    // Comment below line to disable auth0 for local development. DO NOT CHECK IN
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
