<section class="container">
  <h2 class="heading">eContract delivery dashboard</h2>
  <div *ngIf="!noRecordsToDisplay" class="eContract-table-exports">
    <div class="export-table-cont">
      <ul class="export-links">
        <li><a (click)="downloadExcel()" href="javascript:void(0)" tabindex="1"><i class='far fa-file-alt'></i>CSV
            REPORT</a>
        </li>
        <li>|</li>
        <li>
          <a (click)="print()" href="javascript:void(0)" tabindex="1"> <i class='fas fa-print'></i>PRINT</a>
        </li>
      </ul>
    </div>
    <form [formGroup]="determineForm">
      <div class="econtract-table-page-form">
        <span class="records-per-page">Records per page:</span>
        <ent-dropdown formControlName="pageNumber" [options]="selectDropDown"
          (selectionChanged)="onChangePage($event)"></ent-dropdown>
      </div>
    </form>
  </div>
  <div class="annuityContract-table">
    <ent-primary-table [tableData]="annuityContractTableConfig">
      <ng-template #tableCelContentTemplate let-column="column" let-row="row">
        <div *ngIf="column.columnKey === 'clientName'">
          <a [routerLink]="['./econtractdetails', row.contractNumber,row.envelopeStatus]" href="#"
            (click)="generateAnalytics(column.columnKey)" class="policy-link">{{row.clientName}}</a>
        </div>
        <div *ngIf="column.columnKey === 'contractNumber'">
          <a [routerLink]="['./econtractdetails', row.contractNumber,row.envelopeStatus]" href="#"
            (click)="generateAnalytics(column.columnKey)" class="policy-link">{{row.contractNumber}}</a>
        </div>
        <div *ngIf="column.columnKey === 'envelopeStatus'">
          <div *ngIf="row.envelopeStatus === 'COMPLETED'">
            <i class='fa fa-check-circle' style="color:#4BC22B;padding-right:5px;"></i>{{row.envelopeStatus}}
          </div>
          <div *ngIf="row.envelopeStatus === 'AWAITING SIGNATURE'">
            <i class='fas fa-file-signature' style="color:#CE6B27;padding-right:5px;"></i>{{row.envelopeStatus}}
          </div>
          <div *ngIf="row.envelopeStatus === 'DECLINED'">
            <i class='fas fa-ban' style="color:#AD112B;padding-right:5px;"></i>{{row.envelopeStatus}}
          </div>
          <div *ngIf="row.envelopeStatus === 'EXPIRED'">
            <i class='fas fa-hourglass-end' style="color:#FBA51F;padding-right:5px;"></i>{{row.envelopeStatus}}
          </div>
          <div *ngIf="row.envelopeStatus === 'UNKNOWN'">
            <i class='far fa-question-circle' style="color:#CE6B27;padding-right:5px;"></i>{{row.envelopeStatus}}
          </div>
        </div>
      </ng-template>
    </ent-primary-table>
  </div>
</section>
