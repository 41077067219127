import * as _ from 'lodash';
import { AppConstants } from '../shared/constants';
import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
  APP_CONSTANTS = AppConstants;

  /**
   * this is common method use for combining payload
   * @param serviceIdRef this is for service id
   * @param payloadVarPassed this is for payload
   */
  constructPayload(serviceIdRef: any, payloadVarPassed?: any) {
    let payload: any;
    payload['SERVICE_ID'] = serviceIdRef;
    if (payloadVarPassed) {
      _.assign(payload, payloadVarPassed);
    }
    return payload;
  }

  // Handling response errors for the service integration.
  checkServiceStatus() {
    return this.APP_CONSTANTS.STATIC.EXPERIENCING_TECHNICAL_DIFFICULTIES;
  }

  // Print utility method
  stripScripts(s: string): any {
    const div = document.createElement('div');
    div.innerHTML = s;
    const scripts = div.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      scripts[i].parentNode?.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

}
