import { AppConstants, SECURE_SITES } from "src/app/shared/constants";

const host = `${window.location.hostname}`;
let env = 'local';
export let auth0env: 'local' | 'preprod' | 'prod' | 'uat' | 'test' | 'awsdev' | 'dev' | 'awsprod' = 'local';
const siteName = (window as { [key: string]: any })[AppConstants.SITE_NAME] as keyof typeof SECURE_SITES;
const siteURL = AppConstants.SITE_TYPE + SECURE_SITES[siteName];

if (host === 'lincolnfinancial.com' || host === 'www.lincolnfinancial.com') {
  env = 'prod';
  auth0env = 'prod';
} else if (host === 'lincolnfinancial-preprod.lfg.com') {
  env = 'preprod';
  auth0env = 'preprod';
} else if (host === 'lincolnfinancial-uat.lfg.com') {
  env = 'uat';
  auth0env = 'uat';
} else if (host === 'lincolnfinancial-dev.lfg.com') {
  env = 'dev';
  auth0env = 'dev';
} else if (host === 'lincolnfinancial-test.lfg.com') {
  env = 'test';
  auth0env = 'test';
} else if (host === 'annuityecontractui-dev.web.lfg.com') {
  env = 'awsdev';
  auth0env = 'awsdev';
} else if (host === 'annuityecontractui.web.lfg.com') {
  env = 'prod';
  auth0env = 'awsprod';
}

export const environment = {
  envName: env,
  auth0EnvName: auth0env,
  production: true,
  appVersion: '1.0.0',
  basePath : {
    dev: 'https://annuityecontractui-dev.web.lfg.com',
    prod: 'https://annuityecontractui.web.lfg.com'
  },
  auth0: {
    local: {
      // change the client id/domain/audience to diff. env. in local
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'http://localhost:4200'
    },
    dev: {
      clientId: 'l0TDCWwueeCKP3NXUZkkxTOYN7XDuK4z',
      domain: 'https://auth.lincolnfinancial-dev.lfg.com',
      audience: 'https://lincolnfinancial-dev.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-dev.lfg.com/' + siteURL + '/pendingbusiness/annuityecontract'
    },
    test: {
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-test.lfg.com/' + siteURL + '/pendingbusiness/annuityecontract'
    },
    uat: {
      clientId: 'amHLpXAnQHZcURttyXYPAei1MnxRDqsp',
      domain: 'https://auth.lincolnfinancial-uat.lfg.com',
      audience: 'https://lincolnfinancial-uat.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-uat.lfg.com/' + siteURL + '/pendingbusiness/annuityecontract'
    },
    preprod: {
      clientId: 'T57L7F8RdazyDMm6UIiMP5kJjwstGd2z',
      domain: 'https://auth.lincolnfinancial-preprod.lfg.com',
      audience: 'https://lincolnfinancial-preprod.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-preprod.lfg.com/' + siteURL + '/pendingbusiness/annuityecontract'
    },
    prod: {
      clientId: 'YgCbhBq1czqnI2NgYgQyXDlqw1UFYIir',
      domain: 'https://auth.lincolnfinancial.com',
      audience: 'https://www.lincolnfinancial.com/api',
      redirectUri: 'https://www.lincolnfinancial.com/' + siteURL + '/pendingbusiness/annuityecontract'
    },
    awsdev: {
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'https://annuityecontractui-dev.web.lfg.com'
    },
    awsprod: {
      clientId: 'YgCbhBq1czqnI2NgYgQyXDlqw1UFYIir',
      domain: 'https://auth.lincolnfinancial.com',
      audience: 'https://www.lincolnfinancial.com/api',
      redirectUri: 'https://annuityecontractui.web.lfg.com'
    }
  },
  serviceUrls: {
    local: 'https://annuityecontractapi-dev.web.lfg.com/econtract-api/',
    dev: 'https://annuityecontractapi-dev.web.lfg.com/econtract-api/',
    prod: 'https://annuityecontractapi.web.lfg.com/econtract-api/'
  }
};
