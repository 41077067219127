import { Injectable } from "@angular/core";
import { EntAnalyticsEventData, EntAnalyticsService } from "@ng/ent-utilities";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private entAnalyticsService: EntAnalyticsService) { }

  public linkEventAnalytics(
    event_name: string,
    event_type: string,
    event_version: string,
  ): void {
    const eventData: EntAnalyticsEventData = new EntAnalyticsEventData();
    eventData.event_action = 'link';
    eventData.event_name = event_name;
    eventData.event_type = event_type;
    eventData.event_version = event_version;
    this.entAnalyticsService.link(eventData);
  }

  public tabEventAnalytics(
    event_name: string,
    event_type: string,
  ): void {
    const eventData: EntAnalyticsEventData = new EntAnalyticsEventData();
    eventData.event_action = 'tab';
    eventData.event_name = event_name;
    eventData.event_type = event_type;
    this.entAnalyticsService.link(eventData);
  }
}
