import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../shared/constants';
import { DataService } from '../../shared/data-service';
import { timeout } from 'rxjs';
import { EntLoaderService } from "@ng/ent-components/ent-loader";
import { Utils } from '../../shared/utils';
import { Parser } from '@json2csv/plainjs';
import { AnnuityContractTableConfig } from 'src/app/shared/table-config/annuity-contract-table.config';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared/analytics-service';

@Component({
  selector: 'app-annuity-econtracts',
  templateUrl: './annuity-econtracts.component.html',
  styleUrls: ['./annuity-econtracts.component.scss']
})
export class AnnuityEcontractsComponent implements OnInit {
  annuityContractTableConfig = new AnnuityContractTableConfig().tableConfig;
  appConstants = AppConstants;
  private limit = 60000;
  errorMsg: any;
  noRecordsToDisplay = false;
  determineForm!: FormGroup;
  selectDropDown = this.appConstants.SELECT_DROPDOWN;
  printWindow: any;

  constructor(private dataService: DataService, private loaderService: EntLoaderService, private utils: Utils, private fb: FormBuilder,
    private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.determineForm = this.fb.group({
      pageNumber: new FormControl(this.selectDropDown[3], Validators.required)
    });
    this.getListData();
  }

  getListData() {
    this.loaderService.show();
    const resourceType = this.appConstants.PAYLOAD.GET_ECONTRACT_LIST_URL_SERVICE_ID;
    this.dataService.getListTableData(resourceType).pipe(timeout(this.limit))
      .subscribe({
        next: (res) => {
          if (res !== undefined && res.responseStatus === 'SUCCESS') {
            const listData = res.data;
            if (listData.responseData) {
              this.annuityContractTableConfig.data = listData.responseData?.responseData;
              if (this.annuityContractTableConfig.data.length === 0) {
                this.noRecordsToDisplay = true;
              } else {
                this.updateStatus(this.annuityContractTableConfig.data);
              }
            }
          } else if (res.responseStatus === 'FAILURE') {
            this.errorMsg = this.utils.checkServiceStatus();
            this.loaderService.hide();
          }
        },
        error: (e) => {
          const errorMsg = e.status + ' : ' + e.message;
          this.errorMsg = errorMsg ? errorMsg : this.utils.checkServiceStatus();
          this.loaderService.hide();
        },
        complete: () => {
          this.loaderService.hide();
        }
      });
  }

  onChangePage(e: any) { // table page size drop down
    this.annuityContractTableConfig.config.currentPage = 0;
    let pageRecods = Number(e.value);
    this.annuityContractTableConfig.config.rowsPerPage = pageRecods;
  }

  downloadExcel() {
    const csvData = this.generateCsv(this.annuityContractTableConfig.data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', 'eContract delivery list');
    downloadLink.setAttribute('style', 'display:none');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  generateCsv(data: any) {
    const fields = [
      { label: 'CLIENT NAME', value: 'clientName' },
      { label: 'ACCOUNT', value: 'contractNumber' },
      { label: 'STATUS', value: 'envelopeStatus' },
      { label: 'WRITING AGENT', value: 'writingAgentName' }
    ];
    const opts = { fields };
    try {
      data.forEach((x: any) => {
        x.contractNumber = x.contractNumber.replace(/‎/g, '');
      });
      const parser = new Parser(opts);
      const csv = parser.parse(data);
      return csv;
    } catch (e) {
      console.log('Error while generating csv');
    }
    return '';
  }

  print() {
    let envKey: keyof typeof environment.basePath = 'dev';
    if (environment.envName === 'prod') {
      envKey = 'prod';
    }
    const basePath = environment.basePath[envKey];
    const logoPath = basePath + '/assets/images/lfg-logo.svg';
    const heading = document.querySelector('.heading')?.outerHTML;
    const tableData = document.querySelector('.annuityContract-table')?.outerHTML;

    this.printWindow = window.open('', 'Print', 'status=no,menubar=no,scrollbars=yes,resizable=yes,width=1182,height=900,left=10,right=10');
    this.printWindow.document.write('<html><head></head><body>');
    this.printWindow.document.write('' + this.utils.stripScripts(document.head.innerHTML) +
      `<div class='print-page'>
          <header class='print-page__logo'>
            <img alt="Lincoln Financial Group Logo (click to navigate to top page)" class="print-page__logo-img" src="`+ logoPath + `">
          </header>
          <section>
            <div>`+ heading + `</div>
            <div class='print_remove_links'>`+ tableData + `</div>
          </section>
      </div>` +
      '<script> ' +
      'setTimeout(function() { ' +
      'window.document.close();' +
      'window.focus();' +
      'window.print();' +
      '}, 1000)' +
      '</script>'
    );
    this.printWindow.document.write('</body></html>');
  }
  updateStatus(tableData: any) {
    tableData.forEach((x: any) => {
        if(x.envelopeStatus === 'COMPLETE' || x.envelopeStatus === 'COMPLETED') {
          x.envelopeStatus = 'COMPLETED';
        } else if (x.envelopeStatus === 'SENT' || x.envelopeStatus === 'DELIVERED') {
          x.envelopeStatus = 'AWAITING SIGNATURE';
        } else if (x.envelopeStatus === 'VOIDED' || x.envelopeStatus === 'EXPIRED') {
          x.envelopeStatus = 'EXPIRED';
        } else if (x.envelopeStatus === 'DECLINED') {
            x.envelopeStatus = 'DECLINED';
        } else {
          x.envelopeStatus = 'UNKNOWN';
        }
    });
  }

  generateAnalytics(columnKey: string) {
    console.log('set link analytics', columnKey);
    if (columnKey === 'clientName') {
      this.analyticsService.linkEventAnalytics('client name', 'pending tool', 'annuity econtract');
    } else {
      this.analyticsService.linkEventAnalytics('account number', 'pending tool', 'annuity econtract');
    }
  }
}
