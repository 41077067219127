<section class="container">
  <div class="header">
    <h2 class="heading">eContract details</h2>
    <span class="contract">Contract: {{ contractNum$ | async }}</span>
    <span class="back-link">
      <a [routerLink]="['../../']">
        <i class="fas fa-arrow-circle-left"></i>Back to pending list
      </a>
    </span><br><br>
    <span class="contract-status" *ngIf="contractStatus === 'COMPLETED'">Contract Status <i class='fa fa-check-circle'
        style="color:#4BC22B;padding-right:5px;"></i>COMPLETED</span>
    <span class="contract-status" *ngIf="contractStatus === 'AWAITING SIGNATURE'">Contract Status <i class="fas fa-file-signature fa-lg"
        style="color:#CE6B27;padding-right:5px;"></i>AWAITING SIGNATURE</span>
    <span class="contract-status" *ngIf="contractStatus === 'DECLINED'">Contract Status <i class="fas fa-ban fa-lg"
        style="color:#AD112B;padding-right:5px;"></i>DECLINED</span>
    <span class="contract-status" *ngIf="contractStatus === 'EXPIRED'">Contract Status <i class="fas fa-hourglass-end fa-lg"
        style="color:#FBA51F;padding-right:5px;"></i>EXPIRED</span>
    <span class="contract-status" *ngIf="contractStatus === 'UNKNOWN'">Contract Status <i class="far fa-question-circle fa-lg"
        style="color:#CE6B27;padding-right:5px;"></i>UNKNOWN</span>
    <a href="javascript:void(0)" class="print" (click)="print()">
      <i class="fas fa-print"></i>Print page</a>
  </div>

  <div class="clients-info">
    <div class="clients-info-heading"><img src="{{basePath}}/assets/images/clients-icon.svg"
        alt="clients information icon">
      <h3 class="clients-info-heading-text">CLIENT INFORMATION</h3>
    </div>
    <div class="table-responsive table-spacing">
      <table class="table dataTable no-footer dtr-inline client-info-tbl">
        <thead>
          <tr class="tr-th-text">
            <th>LAST NAME</th>
            <th>FIRST NAME</th>
            <th>ROLE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let records of clientsList">
            <td>{{records?.signerName.split(',')[0]}}</td>
            <td>{{records?.signerName.split(',')[1]}}</td>
            <td>{{records.signerRole}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="signers table-responsive table-spacing">
    <div class="signers-heading"><img src="{{basePath}}/assets/images/signers-icon.svg"
        alt="Signers table icon">
      <h3 class="signers-heading-text"> SIGNERS</h3>
    </div>
    <div class="signers-table">
      <ent-primary-table [tableData]="signersTableConfig">
        <ng-template #tableCelContentTemplate let-column="column" let-row="row">
          <div *ngIf="column.columnKey === 'signereSignatureStatus'">
            <div *ngIf="row.signereSignatureStatus === 'COMPLETED'">
              <i class='fa fa-check-circle' style="color:#4BC22B;padding-right:5px;"></i>{{row.signereSignatureStatus}}
            </div>
            <div *ngIf="row.signereSignatureStatus === 'AWAITING SIGNATURE'">
              <i class='fas fa-file-signature' style="color:#CE6B27;padding-right:5px;"></i>{{row.signereSignatureStatus}}
            </div>
            <div *ngIf="row.signereSignatureStatus === 'DECLINED'">
              <i class='fas fa-ban' style="color:#AD112B;padding-right:5px;"></i>{{row.signereSignatureStatus}}
            </div>
            <div *ngIf="row.signereSignatureStatus === 'VOIDED'">
              <i class='fas fa-hourglass-end' style="color:#FBA51F;padding-right:5px;"></i>{{row.signereSignatureStatus}}
            </div>
            <div *ngIf="row.signereSignatureStatus === 'UNKNOWN'">
              <i class='far fa-question-circle' style="color:#CE6B27;padding-right:5px;"></i>{{row.signereSignatureStatus}}
            </div>
          </div>
        </ng-template>
      </ent-primary-table>
    </div>
  </div>
</section>
