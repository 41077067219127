import { Component, OnInit } from '@angular/core';
import { SignersTableConfig } from '../../../shared/table-config/signers-table.config';
import { environment } from '../../../../environments/environment';
import { AppConstants } from '../../../shared/constants';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of, switchMap, timeout } from 'rxjs';
import { Utils } from 'src/app/shared/utils';
import { DataService } from 'src/app/shared/data-service';
import { EntLoaderService } from '@ng/ent-components/ent-loader';

@Component({
  selector: 'app-econtract-details',
  templateUrl: './econtract-details.component.html',
  styleUrls: ['./econtract-details.component.scss']
})

export class EcontractDetailsComponent implements OnInit {

  signersTableConfig = new SignersTableConfig().tableConfig;
  environment = environment;
  clientsList: any = [];
  contractStatus: any;
  constants = AppConstants;
  contractNum$: Observable<string> = new Observable<string>();
  status$: Observable<string> = new Observable<string>();
  private limit = 60000;
  eContractDetails: any;
  errorMsg: any;
  printWindow: any;
  contractId: any;
  basePath: any;

  constructor(private route: ActivatedRoute,
    private loaderService: EntLoaderService,
    private utils: Utils,
    private clientService: DataService) { }

  ngOnInit() {
    let envKey: keyof typeof environment.basePath = 'dev';
    if (environment.envName === 'prod') {
      envKey = 'prod';
    }
    this.basePath = environment.basePath[envKey];
    this.contractNum$ = this.route.paramMap?.pipe(
      switchMap((params: ParamMap) => {
        const abc = params.get('contractnumber')!;
        return of(abc);
      })
    );
    this.status$ = this.route.paramMap?.pipe(
      switchMap((params: ParamMap) => {
        const status = params.get('status')!;
        return of(status);
      })
    );
    this.contractNum$?.forEach(element => {
     this.contractId = element;
    });
    this.status$?.forEach(element => {
      this.contractStatus = element;
     });
    this.getContractDetails(this.contractId);
    window.scrollTo(0, 0);
  }

  getContractDetails(contractId: any) {
    this.loaderService.show();
    const resourceType = this.constants.PAYLOAD.GET_ECONTRACT_DETAILS_URL_SERVICE_ID;
    this.clientService.getDetailsTableData(resourceType, contractId)?.pipe(timeout(this.limit))
      .subscribe({
        next: (res) => {
          if (res !== undefined && res.responseStatus === 'SUCCESS') {
            this.eContractDetails = res.data.responseData;
            if (this.eContractDetails.signerDetails) {
              this.clientsList = this.eContractDetails.clientName;
              this.signersTableConfig.data = this.eContractDetails.signerDetails;
              this.updateSignerStatus(this.signersTableConfig.data);
            }
          } else if (res.responseStatus === 'FAILURE') {
            this.errorMsg = this.utils.checkServiceStatus();
            this.loaderService.hide();
          }
        },
        error: (e) => {
          const errorMsg = e.status + ' : ' + e.message;
          this.errorMsg = errorMsg ? errorMsg : this.utils.checkServiceStatus();
          this.loaderService.hide();
        },
        complete: () => {
          this.loaderService.hide();
        }
      });
  }

  print() {
    const logoPath = this.basePath + '/assets/images/lfg-logo.svg';
    const heading = document.querySelector('.heading')?.outerHTML;
    const contract = document.querySelector('.contract')?.outerHTML;
    const contractStatus = document.querySelector('.contract-status')?.outerHTML;
    const clientsInfo = document.querySelector('.clients-info')?.outerHTML;
    const signersTableData = document.querySelector('.signers')?.outerHTML;

    this.printWindow = window.open('', 'Print', 'status=no,menubar=no,scrollbars=no,resizable=no,width=1182,height=900,left=10,right=10');
    this.printWindow.document.write('<html><head></head><body>');
    this.printWindow.document.write('' + this.utils.stripScripts(document.head.innerHTML) +
      `<div class='print-page'>
          <header class='print-page__logo'>
            <img alt="Lincoln Financial Group Logo (click to navigate to top page)" class="print-page__logo-img" src="`+ logoPath + `">
          </header>
          <section>
            <div>`+ heading + `</div>
            <div>`+ contract + `</div>
            <div>`+ contractStatus + `</div>
            <div>`+ clientsInfo + `</div>
            <div class='no-print'>`+ signersTableData + `</div>
          </section>
      </div>` +
      '<script> ' +
      'setTimeout(function() { ' +
      'window.document.close();' +
      'window.focus();' +
      'window.print();' +
      '}, 1000)' +
      '</script>'
    );
    this.printWindow.document.write('</body></html>');
  }

  updateSignerStatus(tableData: any) {
    tableData?.forEach((x: any) => {
        if(x.signereSignatureStatus === 'COMPLETE' || x.signereSignatureStatus === 'COMPLETED') {
          x.signereSignatureStatus = 'COMPLETED';
        } else if (x.signereSignatureStatus === 'SENT' || x.signereSignatureStatus === 'DELIVERED') {
          x.signereSignatureStatus = 'AWAITING SIGNATURE';
        } else if (x.signereSignatureStatus === 'DECLINED') {
            x.signereSignatureStatus = 'DECLINED';
        } else {
          x.signereSignatureStatus = 'UNKNOWN';
        }
    });
  }
}
