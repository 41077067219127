import { Injectable, isDevMode } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstants } from "./constants";
import { environment } from "../../environments/environment";
import { EntRestClientService } from "@ng/ent-utilities";
import { v4 as uuidv4 } from 'uuid';
import { DatePipe } from '@angular/common';

@Injectable()
export class DataService {
  payload: any;
  APP_CONSTANTS = AppConstants;
  resource: any;

  constructor(
    private entRestClientService: EntRestClientService,
    private datePipe: DatePipe
  ) { }

  /** Service call to get econtracts list */
  getListTableData(resource: string): Observable<any> {
    const data = {
      env: environment.envName
    };
    const payLoad = {
      'transrefGUID'  :  uuidv4(),
      'transExeDate'  : this.getDate(),
      'transExeTime'  : this.getTime()
    };

    if (isDevMode()) {
      return this.entRestClientService.get('/assets/mock/eContractsList.json');
    } else {
      return this.entRestClientService.post(this.getApiUrl(data.env) + resource, payLoad, '', this.getHttpHeaders(data.env));
    }
  }

  /** Service call to get econtract details */
  getDetailsTableData(resource: string, contractId: any): Observable<any> {
    const data = {
      env: environment.envName
    };
    const payLoad = {
      'contractNumber' : contractId,
      'transrefGUID'  :  uuidv4(),
      'transExeDate'  : this.getDate(),
      'transExeTime'  : this.getTime()
    };

    if (isDevMode()) {
        return this.entRestClientService.get('/assets/mock/eContractDetails.json');
    } else {
      return this.entRestClientService.post(this.getApiUrl(data.env) + resource, payLoad, '', this.getHttpHeaders(data.env));
    }
  }

  getApiUrl(env: string): string {
    let envKey: keyof typeof environment.serviceUrls = 'local';
    if (env === 'local' || env === 'test' || env === 'uat' || env === 'preprod' || env === 'awsdev') {
      envKey = 'dev';
    } else if (env === 'prod' || env === 'awsprod') {
      envKey = 'prod';
    }
    try {
      return environment.serviceUrls[envKey];
    } catch (e) {
      console.error('Unable to retrive URL. ' + e);
      throw new Error('Unable to retrieve URL');
    }
  }

  getHttpHeaders(env: string): any {
    const headers = {
      env: env
    };
    return headers;
  }

  getDate(): any {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd','America/New_York','en-US')
  }

  getTime(): any {
    return this.datePipe.transform(new Date(), 'hh:mm:ss','America/New_York','en-US')
  }


}
