import { EntPrimaryTableConfig } from '@ng/ent-components/ent-tables';
import { TableConfig } from '../constants';

export class AnnuityContractTableConfig {
  tableConfig: EntPrimaryTableConfig = {
    config: {
      tableId: 'annuitycontract-table',
      tableClass: 'annuitycontract-table',
      defaultSortKey: 'clientName',
      pagination: true,
      rowsPerPage: 50,
      ...TableConfig
    },
    column: [
      {
        classNameTh: 'clientName-th',
        displayText: 'CLIENT NAME',
        isAction: 'noaction',
        pipeName: undefined,
        type: 'htmlText',
        align: 'left',
        columnKey: 'clientName',
        classNameTd: 'clientName-td',
        sorting: true
      },
      {
        pipeName: undefined,
        type: 'htmlText',
        align: 'left',
        classNameTd: 'account-td',
        displayText: 'ACCOUNT',
        columnKey: 'contractNumber',
        isAction: 'noaction',
        classNameTh: 'account-th',
        sorting: true
      },
      {
        displayText: 'STATUS',
        columnKey: 'envelopeStatus',
        classNameTd: 'status-td',
        sorting: true,
        type: 'htmlText',
        align: 'left',
        classNameTh: 'status-th',
        isAction: 'noaction',
        pipeName: undefined,
      },
      {
        displayText: 'WRITING AGENT',
        columnKey: 'writingAgentName',
        type: 'string',
        align: 'left',
        classNameTh: 'agent-th',
        classNameTd: 'agent-td',
        isAction: 'noaction',
        pipeName: undefined,
        sorting: true
      }
    ],
    data: []
  };
}
