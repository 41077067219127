import { EntPrimaryTableConfig } from "@ng/ent-components/ent-tables";
import { TableConfig } from "../constants";

export class SignersTableConfig {
  tableConfig: EntPrimaryTableConfig = {
    config: {
      tableId: 'signers-table',
      tableClass: 'signers-table',
      defaultSortKey: 'name',
      pagination: false,
      rowsPerPage: 10,
      ...TableConfig
    },
    column: [
      {
        displayText: 'SIGNER',
        columnKey: 'signerName',
        classNameTh: 'signer-th',
        classNameTd: 'signer-td',
        sorting: true,
        isAction: 'noaction',
        pipeName: undefined,
        type: 'string',
        align: 'left',
      },
      {
        align: 'left',
        classNameTh: 'signer-role-th',
        displayText: 'SIGNER ROLE',
        columnKey: 'signerRole',
        type: 'string',
        classNameTd: 'signer-role-td',
        isAction: 'noaction',
        pipeName: undefined,
        sorting: false
      },
      {
        displayText: 'SIGNER EMAIL ADDRESS',
        columnKey: 'signerEmailAddress',
        align: 'left',
        classNameTh: 'signer-email-th',
        type: 'string',
        classNameTd: 'signer-email-td',
        sorting: false,
        isAction: 'noaction',
        pipeName: undefined,
      },
      {
        displayText: 'SIGNER STATUS',
        classNameTd: 'signer-status-td',
        sorting: false,
        columnKey: 'signereSignatureStatus',
        align: 'left',
        pipeName: undefined,
        type: 'htmlText',
        classNameTh: 'signer-status-th',
        isAction: 'noaction',

      }
      /** Will be enable back on day2 */
      // {
      //   displayText: '',
      //   columnKey: 'action',
      //   isAction: 'noaction',
      //   pipeName: undefined,
      //   type: 'htmlText',
      //   align: 'left',
      //   classNameTh: 'signer-act-th',
      //   classNameTd: 'signer-act-td',
      //   sorting: false
      // }
    ],
    data: []
  };
}
